// extracted by mini-css-extract-plugin
export var basicDetailsView = "PostCard-module--basicDetailsView--e5435";
export var gridView = "PostCard-module--gridView--c05df";
export var listView = "PostCard-module--listView--fab62";
export var postBody = "PostCard-module--postBody--fb306";
export var postCard = "PostCard-module--postCard--eccb8";
export var postContent = "PostCard-module--postContent--25be4";
export var postDate = "PostCard-module--postDate--830fd";
export var postImage = "PostCard-module--postImage--00034";
export var postImageContainer = "PostCard-module--postImageContainer--5592f";
export var postTitle = "PostCard-module--postTitle--f05b4";
export var readmore = "PostCard-module--readmore--94008";
export var titleOnly = "PostCard-module--titleOnly--195d6";