import React, { useMemo } from 'react';
import { transformWpPost } from '../../../helpers/wordpress';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import Container from '../../atoms/Container/Container';
import Layout from '../Layout/Layout';
import Seo from '../Seo/Seo';

import { Box, Center, SimpleGrid } from '@chakra-ui/react';
import PostCard from 'components/molecules/PostCard/PostCard';
import * as styles from './BlogList.module.css';

const BlogList = ({ posts, title, name, breadcrumbs = [] }) => {
  const tPosts = useMemo(() => {
    const postsToRender = posts?.map(({ node: post }) => transformWpPost(post));
    return postsToRender;
  }, [posts]);

  return (
    <Layout>
      <Seo title={title}>
        <body className="noFooterMargin" />
      </Seo>
      <div className={styles.blogContainer}>
        <Container size="large">
          <Breadcrumbs crumbs={[{ link: '/', label: '' }, ...breadcrumbs]} />

          <Box as="h3" my={6}>
            {name}
          </Box>

          {/* <Slider {...sliderProps}> */}
          {tPosts?.length > 0 ? (
            <SimpleGrid columns={[1, 2, 3]} gap={4}>
              {tPosts?.map((post, postIndex) => (
                <PostCard
                  index={postIndex}
                  size="large"
                  viewMode="grid"
                  key={post.id}
                  title={post.title}
                  excerpt={post.excerpt}
                  categories={post.categories}
                  slug={post.slug}
                  thumbnail={post.thumbnail_path}
                />
              ))}
            </SimpleGrid>
          ) : (
            <Center minH={250} textAlign={'center'}>
              <h3>Oops! We haven't added any articles here yet. </h3>
            </Center>
          )}
          {/* </Slider> */}

          {/* <section className={styles.featuredPosts}>
            <div className={styles.featuredPostsBackground}>
                <h4 className="text-center">Featured</h4>
                <FeaturedPosts />
            </div>
          </section> */}
          {/*           
          <section className={styles.products}>
            <div className={styles.background}>
                <h4 className="text-center">Best Sellers</h4>
                <FeaturedProducts
                  responsive={[
                    {
                      breakpoint: 880,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                      }
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                      }
                    }
                  ]}
                />
            </div>
          </section> */}
        </Container>
      </div>
    </Layout>
  );
};

export default BlogList;
