import React from 'react';

import Asset from './asset.png'

import * as styles from './ImgPlaceholder.module.css';

const ImgPlaceholder = props => {
    const { imgAlt } = props

    return (
        <div className={styles.root}>
            {Asset && (
                <img src={Asset} alt={imgAlt} />
            )}
        </div>
    );
};

export default ImgPlaceholder