import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import BlogList from '../components/organisms/BlogList/BlogList';

const Tag = props => {
  const { data, pageContext } = props;
  const { title: siteTitle } = data.site.siteMetadata;
  const {
    allWpPost: { edges: posts, totalCount },
    allWpCategory: { nodes: categories },
    wpTag: { name }
  } = data;
  const title = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } with the tag ${name}`;


  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${process.env.SITE_URL}/blog/`} />
      </Helmet>
      <BlogList
      name={name}
        posts={posts || []}
        categories={categories || []}
        pageContext={pageContext}
        breadcrumbs={[
          { link: '/blog/', label: 'Blog' },
          { label: `Tag: ${name}` }
        ]}
        title={`${title} | ${siteTitle}`}
      />
    </>
  );
};

export default Tag;

export const pageQuery = graphql`
  query TagPage($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allWpPost(filter: { tags: { nodes: { elemMatch: { slug: { eq: $slug } } } }}) {
      totalCount
      edges {
        node {
          ...PostListFields
        }
      }
    }
    allWpCategory {
      nodes {
        id
        uri
        name
        description
      }
    }
    
      wpTag(slug: {eq: $slug}){
        name
    
      }
    
  }
`;
