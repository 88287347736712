import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import ImgPlaceholder from '../../atoms/ImgPlaceholder/ImgPlaceholder';
import * as styles from './PostCard.module.css';
import { Box, Flex } from '@chakra-ui/react';
import Icon from '../../atoms/Icon/Icon';

const PostCard = ({
  title,
  excerpt = '',
  thumbnail,
  slug,
  viewMode,
  detailsMode,
  titleOnly,
}) => {
  const excerptStr = useMemo(() => {
    const str = excerpt || "";

    return str
      .replace(/<a class="more-link" (.*?)>(.*?)<\/a>/g, "")
      .replace(/<p>(.*?)<\/p>/g, "$1")
      .replace(/\[...\]/g, "... ");
  }, [excerpt]);

  const classes = useMemo(() => {
    const initClass = [styles.postCard];
    if (viewMode === 'grid') {
      initClass.push(styles.gridView);
    } else {
      initClass.push(styles.listView);
    }

    if (detailsMode === 'basic') {
      initClass.push(styles.basicDetailsView);
    }
    

    return initClass.join(' ');
  }, [viewMode, detailsMode]);

  return (
    <Box as={Link} className={classes} to={slug}>
      {thumbnail ? (
        <div className={`${styles.postImageContainer} ${titleOnly ? styles.titleOnly : ''}`}>
          <img className={styles.postImage} src={thumbnail} alt={title} />
        </div>
      ) : (
        <div className={styles.postImageContainer}>
          <ImgPlaceholder imgAlt={title}></ImgPlaceholder>
        </div>
      )}
      <div className={styles.postBody}>
        <div className={styles.postTitle}>
          {slug && (
            <Link url={slug}>
              <h2
                dangerouslySetInnerHTML={{
                  __html: title
                }}
              ></h2>
            </Link>
          )}
          {!slug && <h2>{title}</h2>}
        </div>
        {!titleOnly && <Flex direction={'column'} justifyContent={'space-between'}>
          {excerptStr && (
            <div
              className={styles.postContent}
              dangerouslySetInnerHTML={{
                __html: excerptStr
              }}
            />
          )}
          <Flex align={'center'} gap={2} my={6} color={'#14617A'}>

          <Link className={styles.readmore} url={slug}>read more</Link>
          <Icon symbol={'arrowWithTail'} />
          </Flex>
        </Flex>}
      </div>
    </Box>
  );
};

PostCard.propTypes = {
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  thumbnail: PropTypes.string,
  slug: PropTypes.string,
  viewMode: PropTypes.string
};

export default PostCard;
